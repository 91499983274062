import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { queryShoppingCart } from "graphql-client-cart-broker/queries/get-cart-page-shopping-cart";
import { ApolloQueryResult, useLazyQuery } from "@apollo/client";
import isEmpty from "lodash.isempty";
import useLocale from "hooks/useLocale/useLocale";
import getCountryFromUrl from "utils/getCountryFromUrl";
import { countryCurrencyMapper } from "resources/countries-config.json";
import useSite from "hooks/useSite";
import { useUpdateCart } from "hooks/useUpdateCart";
import { useMembershipInfoAtomValue } from "hooks/atoms";
import { useUserSessionAtomValue } from "hooks/atoms";
import { UserPreferencesContext } from "customProviders/UserPreferencesProvider";
import { useEffectOnce } from "hooks/useEffectOnce";

interface ShoppingCartContextType {
  cartId?: string | null;
  cart: CartType | null;
  setCart: React.Dispatch<React.SetStateAction<CartType>>;
  loading: boolean;
  refetch: (
    variables?: Partial<QueryGetCartArgs>
  ) => Promise<ApolloQueryResult<Pick<Query, "getCart">>>;
  handlePostalCodeCountryChange: (event: {
    postalCode: string;
    country: string;
  }) => void;
  memberShipLoading: boolean;
  setMembershipLoading: React.Dispatch<React.SetStateAction<Boolean>>;
  cartUpdateloading: boolean;
  setPromoLoading: React.Dispatch<React.SetStateAction<Boolean>>;
}

const checkoutV1ContextDefault: ShoppingCartContextType = {
  cartId: null,
  cart: null,
  setCart: () => {},
  loading: true,
  refetch: (() => {}) as ShoppingCartContextType["refetch"],
  handlePostalCodeCountryChange: () => {},
  memberShipLoading: false,
  setMembershipLoading: () => {},
  cartUpdateloading: false,
  setPromoLoading: () => {}
};

export const ShoppingCartContext = createContext<ShoppingCartContextType>(
  checkoutV1ContextDefault
);

export function ShoppingCartContextProvider({ children }) {
  const { loading: loadingSession } = useUserSessionAtomValue();
  const { rhUser, currentCartId: cartId } = useUserSessionAtomValue();
  const membershipInfo = useMembershipInfoAtomValue();
  const siteId = useSite();
  const locale = useLocale();
  const currencyCode = countryCurrencyMapper?.[getCountryFromUrl() || "US"];
  const [memberShipLoading, setMembershipLoading] = useState(false);
  const [promoLoading, setPromoLoading] = useState(false);
  const { cartUpdateloading, cart, setCart } = useContext(
    UserPreferencesContext
  );
  const { updateCart, loading: loadingUpdateCart } = useUpdateCart({
    callerId: "SHOPPING_CART_CONTEXT",
    onCompleted: async data => {
      setCart(data.updateCart);
    }
  });

  const [callQueryShoppingCart, { loading, refetch }] = useLazyQuery<
    Pick<Query, "getCart">,
    QueryGetCartArgs
  >(queryShoppingCart, {
    variables: {
      cartId,
      locale,
      userType: membershipInfo?.userHasActiveMembership
        ? "MEMBER"
        : rhUser?.userType,
      currencyCode,
      email: rhUser?.email,
      siteId
    },
    onCompleted: data => {
      setCart(data?.getCart);
    },
    onError: () => {},
    fetchPolicy: "no-cache"
  });

  const handlePostalCodeCountryChange = useCallback<
    ShoppingCartContextType["handlePostalCodeCountryChange"]
  >(
    ({ postalCode, country }) => {
      console.warn("handlePostalCodeCountryChange", { postalCode, country });
      updateCart({
        cartId,
        postalCode,
        country
      });
    },
    [cartId, updateCart]
  );

  useEffectOnce(
    () => {
      callQueryShoppingCart();
    },
    !(
      isEmpty(cartId) ||
      (loadingSession && !!cart) ||
      loadingUpdateCart ||
      memberShipLoading ||
      cartUpdateloading
    ),
    []
  );

  useEffect(() => {
    if (
      !(
        isEmpty(cartId) ||
        (loadingSession && !!cart) ||
        loadingUpdateCart ||
        memberShipLoading ||
        cartUpdateloading ||
        promoLoading
      )
    ) {
      callQueryShoppingCart();
    }
  }, [promoLoading]);

  const handleSetCart = useCallback(
    (nextCart: CartType) => {
      setCart(prevCart => {
        const {
          employeeDiscount = prevCart?.employeeDiscount ?? null,
          hasGiftFromMemberRegistry = prevCart?.hasGiftFromMemberRegistry ??
            false
        } = nextCart;

        return {
          ...nextCart,
          employeeDiscount,
          hasGiftFromMemberRegistry
        };
      });
    },
    [setCart]
  );

  return (
    <ShoppingCartContext.Provider
      value={{
        cartId,
        cart,
        setCart: handleSetCart,
        loading,
        refetch,
        handlePostalCodeCountryChange,
        memberShipLoading,
        setMembershipLoading,
        cartUpdateloading,
        setPromoLoading
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
}
